<script>
import {mapGetters} from "vuex";

export default {
  name: "PlayerBetPayback",
  props: ['player', 'me'],
  data() {
    return {
      shouldAnimate: false,
      initialRender: true,
      animationStyle: null,
      hasAnimationPlayed: false,
    };
  },
  computed: {
    ...mapGetters('game', ['roomInfo', 'playerSeats']),
    isWaitingNextTurn() {
      return this.roomInfo.isWaitingNextTurn || false;
    },
    payback() {
      return this.player.payback || 0;
    }
  },
  watch: {
    payback() {
      this.animateBet();
    },
    isWaitingNextTurn: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.initialRender = false;
          this.calculatePosition();
        });
      }
    }
  },
  methods: {
    calculatePosition() {
      const target = this.playerSeats.find(p => p.playerIndex === this.player.idx);

      if (!(target && target.playerRef)) {
        return;
      }

      const targetElement = target.playerRef.querySelector('.player_point');
      const betElement = this.$el;

      if (targetElement && betElement) {
        const targetRect = targetElement.getBoundingClientRect();
        const betRect = betElement.getBoundingClientRect();

        let translateX;

        if (this.me) {
          translateX = targetRect.left - betRect.left + (targetRect.width / 2) - (betRect.width / 2);
        } else {
          const baseX = targetRect.left / 2 - betRect.left / 2;
          translateX = baseX + baseX * 0.5;
        }

        const translateY = targetRect.top - betRect.top + (targetRect.height / 2) - (betRect.height / 2);

        this.animationStyle = {
          opacity: 0,
          transformOrigin: 'center',
          transform: `translate(${translateX}px, ${translateY}px)`
        };
      }
    },
    animateBet() {
      if (!this.initialRender) {
        this.calculatePosition();
        this.shouldAnimate = true;
      }
    },
    animationEnded() {
      this.shouldAnimate = false;
      this.hasAnimationPlayed = true;
    }
  },
}
</script>

<template>
  <div
    v-show="!hasAnimationPlayed"
    class="area_bet_point payback"
    :class="{ 'bet-animate': isWaitingNextTurn && shouldAnimate }"
    :style="isWaitingNextTurn && animationStyle"
    @animationend="animationEnded"
  >
    <div class="bet_point">{{ payback | formatCash }}</div>
  </div>
</template>

<style scoped lang="scss">
.area_bet_point {
  opacity: 1;
  z-index: 99;
  transition: opacity 0.75s ease-in, transform 0.75s ease-out;
}

.payback .bet_point {
  transform-origin: center center;
}
</style>